<template>
  <ValidationObserver @submit="addFine">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="addFineModal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <div class="flex gap-1 rtl:flex-row-reverse">
          <div>
            {{ currentUser.fine_amount ? 'Update Fine of ' : 'Add Fine of ' }}
          </div>
          <div class="capitalize">{{ userName }}</div>
        </div>
      </template>
      <template v-slot>
        <div>
          <DetailCard :details="userDetails" />

          <InputFieldWrapper class="mt-6">
            <UiInput
              v-model="fineDetails.fine_amount"
              type="text"
              name="Fine Amount"
              title="Fine Amount"
              label="Fine Amount"
              placeholder="Fine Amount"
              class="flex-1"
              rules="required|numeric"
            />
            <UiInput
              v-model="fineDetails.fine_reason"
              type="text"
              name="Fine Reason"
              title="Fine Reason"
              label="Fine Reason"
              placeholder="Fine Reason"
              class="flex-1"
              rules="alpha_numeric_spaces"
            />
          </InputFieldWrapper>
        </div>
      </template>
      <template v-slot:footer>
        <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
        <UIButton button="primary" :disabled="isLoading">
          <span v-if="isLoading">
            <Loader />
          </span>
          <span v-else v-i18n="fees">Add</span>
        </UIButton>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { mapState, mapActions } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import UIButton from '@src/components/UiElements/UIButton.vue'
import DetailCard from '@src/components/UiElements/DetailCard.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    UiModalContainer,
    Loader,
    ValidationObserver,
    UIButton,
    DetailCard,
    InputFieldWrapper,
  },
  props: {
    addFineModal: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
      default: () => {},
    },
    idx: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['toggle', 'showAddFineModal'],

  data() {
    return {
      fees: 'fees',
      GENERAL_CONSTANTS,
      fineDetails: {
        fine_reason: '',
        fine_amount: '',
        student_fee_id: '',
      },
      userDetails: {},
    }
  },
  computed: {
    ...mapState('layout', ['isLoading']),
    userName() {
      return this.currentUser.student.first_name + ' ' + this.currentUser.student.last_name
    },
  },
  created() {
    this.userDetails = this.transformUserDetails()
    if (this.currentUser.fine_amount) this.fineDetails.fine_amount = this.currentUser.fine_amount
  },
  methods: {
    ...mapActions('fee', ['addFineInFee']),
    transformUserDetails() {
      return {
        student: this.userName,
        dueDate: this.$filters.formattedDate(this.currentUser.due_date),
        totalFee: this.currentUser.fee_amount,
        feeTitle: this.currentUser.title,
        feeType: this.currentUser.fee_type && this.currentUser.fee_type.title,
        payableAmount: this.currentUser.payable_amount,
        discountAmount: this.currentUser.discount_amount,
        fineAmount: this.currentUser.fine_amount,
        paidAmount: this.currentUser.paid_amount,
      }
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.$emit('showAddFineModal')
          break
        case 'close':
          this.$emit('showAddFineModal')
          break
        case 'cancel':
          this.$emit('showAddFineModal')
          break
      }
    },

    addFine() {
      const payeeName = this.currentUser?.student?.full_name
      const amount = this.fineDetails?.fine_amount

      this.$store.commit('layout/IS_LOADING', true)
      this.fineDetails.fine_amount = parseInt(this.fineDetails.fine_amount)
      this.fineDetails.student_fee_id = this.currentUser.id
      this.addFineInFee(this.fineDetails)
        .then((response) => {
          this.$store.commit('toast/NEW', {
            type: 'success',
            message: `${this.$t('toast.Fine')} ${this.$t('toast.for')} ${amount} ${this.$t(
              'toast.of',
            )} ${payeeName} ${this.$t('toast.is added successfully')}`,
          })
          this.$emit('toggle')
          this.$store.commit('layout/IS_LOADING', false)
          this.$emit('showAddFineModal')
        })
        .catch((err) => {
          this.$store.commit('layout/IS_LOADING', false)
        })
    },
  },
}
</script>
