<template>
  <PayableFilters
    :csv-filter-options="displayedFilterOptions"
    :already-selected-displayed-columns="filtersData?.displayedColumns"
    @apply="applyFilters"
  />
  <div class="minHieght100 main-content mt-5">
    <div v-if="!isLoading && isEmpty(studentReceivableFees)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else-if="!isLoading && !isEmpty(studentReceivableFees)">
      <THead>
        <TRHead>
          <TH
            v-for="(head, index) in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, ['Student Name'])"
            :key="index"
          >
            <span v-i18n="fees">
              {{ head.dataTableName }}
            </span>
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(fee, index) in studentReceivableFees" :key="index">
          <TD>
            <SingleUserDisplay
              :required-items="1"
              :user="fee.student"
              label="full_name"
              :image="true"
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Fee Title')">
            {{
              (fee.section_fee && fee.section_fee.title) ||
              fee.title ||
              GENERAL_CONSTANTS.NOT_APPLICABLE
            }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Fee Type')">
            {{ (fee.fee_type && fee.fee_type.title) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Total Fee')">
            {{ fee.fee_amount }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Paid')">
            {{ fee.paid_amount }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Payable')">
            {{ fee.payable_amount }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Discount')">
            {{ fee.discount_amount || GENERAL_CONSTANTS.AMOUNT_ZERO }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Fine')">
            {{ fee.fine_amount || GENERAL_CONSTANTS.AMOUNT_ZERO }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Currency')">
            <span class="uppercase">
              {{ fee.currency || GENERAL_CONSTANTS.AMOUNT_ZERO }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Due Date')">
            {{ $filters.formattedDate(fee.due_date) }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Status')">
            <PaymentStatus :status="fee.status" />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <div v-else class="mt-28">
      <Loader :content="true" />
    </div>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(studentReceivableFeesCounts, filteredRecordLimit)"
      @filterRecord="filterRecord"
    />
    <!-- Accept Payment modal -->
    <AcceptPayment
      v-if="acceptPaymentModal"
      :accept-payment-modal="acceptPaymentModal"
      :current-user="currentUser ? currentUser : {}"
      :idx="currentUserPosition"
      @showAcceptPaymentModal="showAcceptPaymentModal"
    />
    <!-- Add Fine Modal -->
    <AddFine
      v-if="addFineModal"
      :add-fine-modal="addFineModal"
      :current-user="currentUser ? currentUser : {}"
      :idx="currentUserPosition"
      @showAddFineModal="showAddFineModal"
    />
    <!-- Add Discount Modal -->
    <AddDiscount
      v-if="addDiscountModal"
      :add-discount-modal="addDiscountModal"
      :current-user="currentUser ? currentUser : {}"
      :idx="currentUserPosition"
      @showDiscountModal="showDiscountModal"
    />
    <!-- Add Discount Modal -->
    <ChangeDueDateModal
      v-if="changeDueDate"
      :change-due-date="changeDueDate"
      :current-user="currentUser ? currentUser : {}"
      :idx="currentUserPosition"
      @showDueDateModal="showDueDateModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PaymentStatus from '@/src/router/views/finance/fees/feeActionsModals/PaymentStatus.vue'
import AcceptPayment from '@views/finance/fees/feeActionsModals/Accept-Payment.vue'
import AddFine from '@views/finance/fees/feeActionsModals/Add-Fine-Modal.vue'
import AddDiscount from '@views/finance/fees/feeActionsModals/Add-Discount-Modal.vue'
import ChangeDueDateModal from '@views/finance/fees/feeActionsModals/Change-Due-Date.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import GeneralMixins from '@src/mixins/general-mixins.js'
import { isEmpty } from 'lodash'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import {
  showColumnInDataTable,
  removeEmptyKeysFromObject,
  objectDeepCopy,
} from '@utils/generalUtil'
import receivableData from '@src/router/views/student/profile/receivable.json'
import fileMixin from '@src/mixins/file-mixins'
import PayableFilters from '@components/fee/students/PayableFilters.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    PaymentStatus,
    AcceptPayment,
    AddFine,
    AddDiscount,
    Pagination,
    Loader,
    ChangeDueDateModal,
    NoRecordFound,
    SingleUserDisplay,
    PayableFilters,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixins, scrollMixin, fileMixin],
  data() {
    return {
      isEmpty,
      options: ['Select'],
      discount: 'discount',
      dashboard: 'dashboard',
      isLoading: false,
      tableHeaders: receivableData.tableHeaders,
      defaultSelectedColumns: receivableData.defaultSelectedColumns,
      alwaysDisplayColumnsForCSV: {
        'student.first_name': 'First Name',
        'student.last_name': 'Last Name',
      },
      addFeeData: {
        student_id: '',
        fee_type_id: '',
        fee_amount: '',
        status: 'Due',
        payment_method: '',
        due_date: '',
        section_id: '',
      },
      actionList: receivableData.actionList,
      fees: 'fees',
      acceptPaymentModal: false,
      addFineModal: false,
      addDiscountModal: false,
      changeDueDate: false,
      currentUser: {},
      currentUserPosition: '',
      filteredRecordLimit: 10,
      showPagination: false,
      studentList: [],
      GENERAL_CONSTANTS,
      filtersData: {},
    }
  },

  computed: {
    ...mapState({
      studentReceivableFees: (state) => state.student.studentReceivableFees?.records || [],
      studentReceivableFeesCounts: (state) =>
        state.student.studentReceivableFees?.meta?.total_records,
      feeTemplates: (state) => state.fee.feeTemplates?.data,
      classes: (state) => state.layout.classList,
      feeTypes: (state) => state.fee.feeTypes,
      currentSectionScope: (state) => state.layout.currentSectionScope?.id,
    }),
  },
  watch: {
    currentSectionScope: {
      handler() {
        this.filterRecord()
      },
    },
  },
  /**
   * Mounted hook
   * @description Calling FilterRecord method
   */
  mounted() {
    this.filterRecord()
  },
  /**
   * Created hook
   * @description Setting static right bar data
   */
  created() {
    this.setRightBar()
  },
  methods: {
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('fee', ['sendFeeNotification', 'deleteStudentFee']),
    ...mapActions('student', ['getStudentReceivableFee', 'loadClassStudents']),
    showColumnInDataTable,

    applyFilters(filtersPayload) {
      removeEmptyKeysFromObject(filtersPayload)
      this.filtersData = objectDeepCopy(filtersPayload)
    },
    /**
     * Show Accept Payment Modal
     * @description Negates the value of accept-payment modal
     */

    showAcceptPaymentModal() {
      this.acceptPaymentModal = !this.acceptPaymentModal
    },

    /**
     * Show Add Fine Modal
     * @description Negates the value of add-fine modal
     */

    showAddFineModal() {
      this.addFineModal = !this.addFineModal
    },

    /**
     * Show Discount Modal
     * @description Negates the value of add-discount modal
     */

    showDiscountModal() {
      this.addDiscountModal = !this.addDiscountModal
    },

    /**
     * Show Due Date Modal
     * @description Negates the value of change-due-date modal
     */

    showDueDateModal() {
      this.changeDueDate = !this.changeDueDate
    },

    /**
     * Fee Action
     * @param {String} action
     * @param {object} currentStudent
     * @param {number} idx - Index of current student
     * @returns {void}
     * @description Show the modal on basis of action
     */

    feeAction(action, currentStudent, idx) {
      this.currentUser = currentStudent
      this.currentUserPosition = idx
      switch (action) {
        case 'Accept Payment':
          this.showAcceptPaymentModal()
          break
        case 'Add fine':
          this.showAddFineModal()
          break
        case 'Add discount':
          this.showDiscountModal()
          break
        case 'Send Notification':
          this.sendNotificationToUser(this.currentUser)
          break
        case 'Delete':
          this.deleteFee(currentStudent.id, idx)
          break
        case 'Change due date':
          this.showDueDateModal()
          break
      }
    },

    setRightBar(count = 0) {
      const student_id = JSON.parse(localStorage.getItem('currentActiveStudent'))?.id || '0'
      const rightBarContent = {
        header: {
          title: 'Fees',
          buttons: [
            {
              title: 'DOWNLOAD_CSV',
              icon: 'download',
              iconHeight: '18',
              iconWidth: '18',
              classes: ['primary-button-right'],
              action: {
                func: () => this.downloadCSV('studentPayable', { student_id }),
              },
              permissions: {
                basedOn: ['section-level'],
              },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'PAYABLE', value: count }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    /**
     * Filter Record
     * @param {number} range
     * @returns {void}
     * @description Method description
     * -  Filter the record on basis of range
     * -  Setting right bar data
     */

    filterRecord(range) {
      this.isLoading = true
      this.getStudentReceivableFee({
        skip: range?.skip || 0,
        limit: range?.limit || 10,
      }).then((response) => {
        // getting first_name and_last name from JSON and storing them in the full_name key for options in Badge display
        response?.data.records.map((record) => {
          record.student.full_name = `${record.student.first_name} ${record.student.last_name}`
        })
        this.isLoading = false
        this.showPagination = this.studentReceivableFeesCounts > 10
        this.setRightBar(response?.data.meta.total_records)
      })
    },

    /**
     * Send Notification to User
     * @param {object} currentUser
     * @returns {void}
     * @description Sending notification to user
     */

    sendNotificationToUser(currentUser) {
      const payload = {
        student_fees: [currentUser.id],
      }
      this.sendFeeNotification(payload)
    },

    /**
     * Delete Fee
     * @param {number} id - Current id of fee
     * @param {number} idx - Current index of fee id
     * @returns {void}
     * @description Deleting fee on the basis of id
     */

    deleteFee(id, idx) {
      this.deleteStudentFee(id).then(() => {
        this.$store.commit('fee/DELETE_STUDENT_FEE', idx)
      })
    },
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
