<template>
  <div>
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="changeDueDate"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <div class="">
          <span v-i18n="fees">Change Due Date of</span>
          <span class="capitalize">
            {{ studentName }}
          </span>
          <span v-i18n="fees">Fees</span>
        </div>
      </template>
      <template v-slot>
        <div class="w-100">
          <DetailCard :details="userDetails" />
          <InputFieldWrapper class="mt-6">
            <UiDatePicker
              v-model="dateData.due_date"
              :date-mode="DATE_MODE.END_DATE_TIME"
              title="Date"
              class="flex-1"
            />
          </InputFieldWrapper>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading" @click="changeDate()">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>Accept</span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </div>
</template>

<script>
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { mapState, mapActions } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import { formatDate } from '@utils/moment.util'
import { DATE_MODE } from '@/src/constants/date-time-constants.js'
import { objectKeyIntoCapitalizeWords } from '@utils/generalUtil.js'
import DetailCard from '@src/components/UiElements/DetailCard.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiDatePicker,
    UiModalContainer,
    Loader,
    DetailCard,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    changeDueDate: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
      default: () => {},
    },
    idx: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['showDueDateModal'],
  data() {
    return {
      DATE_MODE,
      fees: 'fees',
      date: new Date(),
      dateData: {
        due_date: '',
        student_fee_id: [],
        formatDate,
      },
      GENERAL_CONSTANTS,
      userDetails: {},
    }
  },
  computed: {
    ...mapState('layout', ['isLoading']),
    studentName() {
      return this.currentUser.student.full_name
    },
  },
  created() {
    this.userDetails = this.transformUserDetails()
  },
  mounted() {
    this.dateData.due_date = this.currentUser.due_date
  },
  methods: {
    ...mapActions('fee', ['changeReceivableFeeDueDate']),
    transformUserDetails() {
      return {
        student: this.studentName,
        dueDate: this.$filters.formattedDate(this.currentUser.due_date),
        totalFee: this.currentUser.fee_amount,
        feeTitle: this.currentUser.title,
        feeType: this.currentUser.fee_type && this.currentUser.fee_type.title,
        payableAmount: this.currentUser.payable_amount,
        discountAmount: this.currentUser.discount_amount,
        fineAmount: this.currentUser.fine_amount,
        paidAmount: this.currentUser.paid_amount,
      }
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.$emit('showDueDateModal')
          break
        case 'close':
          this.$emit('showDueDateModal')
          break
        case 'cancel':
          this.$emit('showDueDateModal')

          break
        case 'confirm':
          break
      }
    },
    changeDate() {
      const payeeName = objectKeyIntoCapitalizeWords(this.currentUser?.student?.full_name)
      this.$store.commit('layout/IS_LOADING', true)
      this.dateData.student_fee_id = this.currentUser.id
      this.changeReceivableFeeDueDate(this.dateData)
        .then((response) => {
          let message = `${this.$t('toast.Due Date')}  ${this.$t(
            'toast.for',
          )} ${payeeName}  ${this.$t('toast.successfully changed')} ${this.$t(
            'toast.to',
          )}  ${formatDate(response.data.due_date)}`
          this.$store.commit(
            'toast/NEW',
            {
              message: message,
              type: 'success',
            },
            { root: true },
          )

          const payload = {
            index: this.idx,
            due_date: response.data.due_date,
          }
          this.$emit('showDueDateModal', true)
          this.$store.commit('layout/IS_LOADING', false)
          this.$store.commit('fee/CHANGE_DUE_DATE', payload)
        })
        .catch((err) => {
          this.$store.commit('layout/IS_LOADING', false)
        })
    },
  },
}
</script>
