<template>
  <div>
    <ValidationObserver @submit="acceptPayment">
      <UiModalContainer
        footer-classes="flex justify-end gap-3"
        :modal-show="acceptPaymentModal"
        :modal-width="50"
        @handleClick="handleClick"
      >
        <template v-slot:header>
          <div>
            <span v-i18n="fees">Accept Payment of</span>
            <span class="capitalize">{{ userName }}</span>
          </div>
        </template>
        <template v-slot>
          <div>
            <DetailCard :details="userDetails" />

            <InputFieldWrapper class="mt-6">
              <UiInput
                v-model.number="paymentDetails.paid_amount"
                type="text"
                name="Receiving Amount"
                title="Receiving Amount"
                label="Receiving Amount"
                placeholder="Receiving Amount"
                class="flex-1"
                rules="numeric|required"
              />
              <UiDatePicker
                v-model="paymentDetails.paid_date"
                title="Date"
                :date-mode="DATE_MODE.END_DATE_TIME"
                class="flex-1"
                rules="required"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiInput
                v-model="paymentDetails.payment_comment"
                type="text"
                name="PAYMENT_COMMENT"
                title="PAYMENT_COMMENT"
                label="PAYMENT_COMMENT"
                placeholder="PAYMENT_COMMENT"
                class="flex-1"
              />
              <UiInput
                v-model="paymentDetails.payment_method"
                type="text"
                name="Payment Method"
                title="Payment Method"
                label="Payment Method"
                placeholder="Payment Method"
                class="flex-1"
              />
            </InputFieldWrapper>
          </div>
        </template>
        <template v-slot:footer>
          <UIButton :disabled="isLoading" @click="handleClick('cancel')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else v-i18n="fees">Accept</span>
          </UIButton>
        </template>
      </UiModalContainer>
    </ValidationObserver>
  </div>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'

import { mapState, mapActions } from 'vuex'
import { Form as ValidationObserver } from 'vee-validate'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import { DATE_MODE } from '@/src/constants/date-time-constants.js'
import DetailCard from '@src/components/UiElements/DetailCard.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    UiDatePicker,
    UiModalContainer,
    Loader,
    ValidationObserver,
    UIButton,
    DetailCard,
    InputFieldWrapper,
  },
  props: {
    acceptPaymentModal: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
      default: () => {},
    },
    idx: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['showAcceptPaymentModal', 'updateResults'],

  data() {
    return {
      fees: 'fees',
      DATE_MODE,
      GENERAL_CONSTANTS,
      paymentDetails: {
        paid_amount: null,
        payment_method: '',
        payment_comment: '',
        student_fee_id: '',
      },
      userDetails: {},
    }
  },
  computed: {
    ...mapState('layout', ['isLoading']),
    userName() {
      return this.currentUser.student.first_name + ' ' + this.currentUser.student.last_name
    },
  },
  created() {
    this.userDetails = this.transformUserDetails()
  },
  methods: {
    transformUserDetails() {
      return {
        student: this.userName,
        dueDate: this.$filters.formattedDate(this.currentUser.due_date),
        totalFee: this.currentUser.fee_amount,
        feeTitle: this.currentUser.title,
        feeType: this.currentUser.fee_type && this.currentUser.fee_type.title,
        payableAmount: this.currentUser.payable_amount,
        discountAmount: this.currentUser.discount_amount,
        fineAmount: this.currentUser.fine_amount,
        paidAmount: this.currentUser.paid_amount,
      }
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.$emit('showAcceptPaymentModal')
          break
        case 'close':
          this.$emit('showAcceptPaymentModal')
          break
        case 'cancel':
          this.$emit('showAcceptPaymentModal')

          break
        case 'confirm':
          break
      }
    },
    acceptPayment() {
      this.$store.commit('layout/IS_LOADING', true)
      this.paymentDetails.student_fee_id = this.currentUser.id
      this.acceptStudentFee(this.paymentDetails)
        .then((res) => {
          /* INVOICE PRINT MODEL */
          this.$store.commit('fee/SET_SHOW_MODAL', true)
          this.$store.commit('fee/SET_FEE_INVOICE', res.data)
          /* INVOICE PRINT MODEL END */
          const feeType = this.currentUser?.fee_type?.title
          const payeeName = this.currentUser?.student?.full_name
          const amount = this.paymentDetails.paid_amount
          this.$store.commit('toast/NEW', {
            type: 'success',
            message: `${feeType} ${this.$t('toast.of')} ${payeeName} ${this.$t(
              'toast.for',
            )} ${amount} ${this.$t('toast.is received successfully')}`,
          })
          this.$emit('showAcceptPaymentModal')
          this.$store.commit('layout/IS_LOADING', false)
          this.$emit('updateResults')
          const data = {
            index: this.idx,
            payment: this.paymentDetails.paid_amount,
          }
          this.$store.commit('fee/ACCEPT_STUDENT_FEE_PAYMENT', data)
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
        })
    },
    ...mapActions('fee', ['acceptStudentFee']),
  },
}
</script>

<style>
@media screen and (max-width: 424px) {
  .xsm {
    display: flex;
    flex-direction: column;
  }
}
</style>
