<template>
  <ValidationObserver @submit="addDiscount">
    <div>
      <UiModalContainer
        footer-classes="flex justify-end gap-3"
        :modal-show="addDiscountModal"
        :modal-width="50"
        @handleClick="handleClick"
      >
        <template v-slot:header>
          <div class="flex gap-1 rtl:flex-row-reverse">
            <div v-i18n="fees">Add</div>
            <div>Discount of</div>
            <div class="capitalize">{{ userName }}</div>
          </div>
        </template>
        <template v-slot>
          <div>
            <DetailCard :details="userDetails" />
            <InputFieldWrapper class="mt-6">
              <UiSingleSelect
                v-model="discountDetails.discount_type_id"
                title="Select Discount Type"
                :options="discountList"
                label="title"
                class="flex-1"
                reduce="id"
                rules="required"
                :search-through-api="true"
                @search="discountTypes"
              />
              <UiInput
                v-model="discountDetails.discount_amount"
                type="text"
                name="Discount Amount"
                title="Discount Amount"
                label="Discount Amount"
                placeholder="Discount Amount"
                class="flex-1"
                rules="required|numeric"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiInput
                v-model="discountDetails.discount_comment"
                type="text"
                name="Discount Comment"
                title="Discount Comment"
                label="Discount Comment"
                placeholder="Discount Comment"
                class="flex-1"
              />
            </InputFieldWrapper>
          </div>
        </template>
        <template v-slot:footer>
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else v-i18n="fees">Add</span>
          </UIButton>
        </template>
      </UiModalContainer>
    </div>
  </ValidationObserver>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { mapState, mapActions } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { Form as ValidationObserver } from 'vee-validate'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import UIButton from '@src/components/UiElements/UIButton.vue'
import DetailCard from '@src/components/UiElements/DetailCard.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'

export default {
  components: {
    UiInput,
    UiModalContainer,
    Loader,
    UiSingleSelect,
    ValidationObserver,
    UIButton,
    DetailCard,
    InputFieldWrapper,
  },
  props: {
    addDiscountModal: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
      default: () => {},
    },
    idx: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['toggle', 'showDiscountModal'],

  data() {
    return {
      fees: 'fees',
      GENERAL_CONSTANTS,
      discountTypesList: [],
      discountDetails: {
        discount_amount: '',
        discount_comment: '',
        discount_type_id: '',
        student_fee_id: '',
      },
      userDetails: {},
    }
  },
  computed: {
    ...mapState('layout', ['isLoading']),
    ...mapState({
      discountList: (state) => state.fee.discountTypes?.records || [],
    }),
    userName() {
      return this.currentUser.student.first_name + ' ' + this.currentUser.student.last_name
    },
  },
  created() {
    this.userDetails = this.transformUserDetails()
    this.discountTypes()
    if (this.currentUser.discount_amount) {
      this.discountDetails.discount_amount = this.currentUser.discount_amount
      this.discountDetails.discount_type_id = this.currentUser.discount_type_id
    }
  },
  methods: {
    transformUserDetails() {
      return {
        student: this.userName,
        dueDate: this.$filters.formattedDate(this.currentUser.due_date),
        totalFee: this.currentUser.fee_amount,
        feeTitle: this.currentUser.title,
        feeType: this.currentUser.fee_type && this.currentUser.fee_type.title,
        payableAmount: this.currentUser.payable_amount,
        discountAmount: this.currentUser.discount_amount,
        fineAmount: this.currentUser.fine_amount,
        paidAmount: this.currentUser.paid_amount,
      }
    },
    discountTypes(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getDiscountTypes(payload)
    },
    ...mapActions('fee', ['addDiscountInFee', 'getDiscountTypes']),
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.$emit('showDiscountModal')
          break
        case 'close':
          this.$emit('showDiscountModal')
          break
        case 'cancel':
          this.$emit('showDiscountModal')

          break
        case 'confirm':
          break
      }
    },
    addDiscount() {
      this.$store.commit('layout/IS_LOADING', true)
      this.discountDetails.student_fee_id = this.currentUser.id
      this.discountDetails.discount_amount = parseInt(this.discountDetails.discount_amount)
      this.addDiscountInFee(this.discountDetails).then(() => {
        const discountType = this.currentUser?.discount_type?.title
        const payeeName = this.currentUser?.student?.full_name
        const amount = this.discountDetails?.discount_amount
        this.$store.commit('toast/NEW', {
          type: 'success',
          message: `${discountType} ${this.$t('toast.discount')} ${this.$t(
            'toast.for',
          )} ${amount} ${this.$t('toast.of')} ${payeeName} ${this.$t(
            'toast.is added successfully',
          )}`,
        })

        this.$emit('toggle')
        this.$store.commit('layout/IS_LOADING', false)
        this.$emit('showDiscountModal')
      })
    },
  },
}
</script>
